<template>
  <div
    class="KeyboardBtn BtnUnknown"
    :style="{ zIndex }"
    @click="handleFocus"
  >
    <span
      v-if="showWords"
      :class="{
        uppercase: item.keyName && item.keyName.length === 1
      }"
    >{{ item.keyName }}</span>
  </div>
</template>

<script>
import BtnMixins from './BtnMixins'
export default {
  name: 'BtnUnknown',
  mixins: [BtnMixins],
  data () {
    return {
    }
  }
}
</script>

<style lang="less">
  .KeyboardBtn {
    &.BtnUnknown {
      border-color: tomato !important;
      &:active {
        span {
          transform: scale(.9);
        }
      }
      .uppercase {
        text-transform: uppercase;
      }
    }
  }
</style>
