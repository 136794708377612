<template>
  <div
    class="KeyboardBtn BtnWASD"
    :style="{ zIndex }"
    @click="handleFocus"
  >
    <div class="inner-wrapper">
      <div
        v-for="item in keyList"
        :key="item.words"
        class="btn"
        @click="handleClick(item.words)"
      >
        <div
          class="words"
          :style="{
            backgroundImage: `url(${item.image})`
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BtnMixins from './BtnMixins'

export default {
  name: 'BtnWASD',
  mixins: [BtnMixins],
  data () {
    return {
      keyList: [
        { words: 'W', image: require('@/assets/img/keyboard/W@2x.png') },
        { words: 'A', image: require('@/assets/img/keyboard/A@2x.png') },
        { words: 'S', image: require('@/assets/img/keyboard/S@2x.png') },
        { words: 'D', image: require('@/assets/img/keyboard/D@2x.png') }
      ]
    }
  },
  methods: {
    handleClick (words) {
      console.log(words)
    }
  }
}
</script>

<style lang="less">
.KeyboardBtn {
  &.BtnWASD {
    overflow: hidden;
    .inner-wrapper {
      width: 100%;
      height: 100%;
      transform: rotate(45deg);
      display: flex;
      flex-wrap: wrap;
      .btn {
        width: 50%;
        height: 50%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        &:active {
          background-color: rgba(0, 0, 0, 0.3);
          .words {
            transform: rotate(-45deg) scale(.9);
          }
        }
        .words {
          @s: 16%;
          width: @s;
          height: @s;
          transform: rotate(-45deg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}
</style>
