// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/keyboard/delete@2x.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/keyboard/delete-words@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".KeyboardBtn.BtnDelete:active .content{transform:scale(.9)}.KeyboardBtn.BtnDelete .content{width:100%;height:100%;display:flex;flex-direction:column;justify-content:center;align-items:center}.KeyboardBtn.BtnDelete .content .icon{width:46%;height:46%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;background-position:50%}.KeyboardBtn.BtnDelete .content .name{width:34%;height:30%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:contain;background-repeat:no-repeat;background-position:50%}", ""]);
// Exports
module.exports = exports;
