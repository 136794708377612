<template>
  <div
    class="VirtualKeyboard"
    :style="{
      width: width + 'px',
      height: width * (9/16) + 'px'
    }"
  >
    <span
      v-for="(item, index) in KeyboardInfo.keyInfo"
      :key="index"
    >
      <components
        :is="computedButtonInstance(item)"
        class="buttons"
        :max-index="zIndex"
        :style="computedStyle(item)"
        :show-words="width> 500"
        :item="item"
        @index="zIndex = $event"
      />
    </span>
    <!-- <div
      :key="index"
      class="buttons"
    >
      <span v-if="width > 500">{{ item.keyName }}</span>
    </div> -->
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import BtnCircle from '@/components/VirtualKeyboard/BtnCircle.vue'
import BtnWASD from '@/components/VirtualKeyboard/BtnWASD.vue'
import BtnMouseRight from '@/components/VirtualKeyboard/BtnMouseRight.vue'
import BtnMouseLeft from '@/components/VirtualKeyboard/BtnMouseLeft.vue'
import BtnMouseMiddle from '@/components/VirtualKeyboard/BtnMouseMiddle.vue'
import BtnScrollUp from '@/components/VirtualKeyboard/BtnScrollUp.vue'
import BtnScrollDown from '@/components/VirtualKeyboard/BtnScrollDown.vue'
import BtnShift from '@/components/VirtualKeyboard/BtnShift.vue'
import BtnSpace from '@/components/VirtualKeyboard/BtnSpace.vue'
import BtnDelete from '@/components/VirtualKeyboard/BtnDelete.vue'
import BtnTab from '@/components/VirtualKeyboard/BtnTab.vue'
import BtnEnter from '@/components/VirtualKeyboard/BtnEnter.vue'
import BtnCaps from '@/components/VirtualKeyboard/BtnCaps.vue'
import BtnUnknown from '@/components/VirtualKeyboard/BtnUnknown.vue'
import BtnGroup from '@/components/VirtualKeyboard/BtnGroup.vue'
import BtnDisc from '@/components/VirtualKeyboard/BtnDisc.vue'

const ButtonNameMap = {
  wasd: BtnWASD.name,
  'Middle button': BtnMouseMiddle.name,
  'Left button': BtnMouseLeft.name,
  'Right button': BtnMouseRight.name,
  'Scroll up': BtnScrollUp.name,
  'Scroll down': BtnScrollDown.name,
  Shift: BtnShift.name,
  Space: BtnSpace.name,
  Back: BtnDelete.name,
  Tab: BtnTab.name,
  Enter: BtnEnter.name,
  Caps: BtnCaps.name
}
const RockerTypeMap = {
  DEFAULT: -1,
  MOUSE: 100,
  DRAG_WASD: 101, // 滑动wasd,
  PRESS_WASD: 103, // 点按wasd
  PRESS_URBL: 104, // 点按 上下左右
  LEFT: 105, // 虚拟手柄左
  RIGHT: 106, // 虚拟手柄右
  GROUP: 107, // 组合
  ROULETTE: 108 // 轮盘
}

export default {
  name: 'VirtualKeyboardComp',
  components: {
    BtnCircle,
    BtnWASD,
    BtnMouseRight,
    BtnMouseLeft,
    BtnMouseMiddle,
    BtnScrollUp,
    BtnScrollDown,
    BtnShift,
    BtnSpace,
    BtnDelete,
    BtnTab,
    BtnEnter,
    BtnCaps,
    BtnUnknown,
    BtnGroup,
    BtnDisc
  },
  props: {
    width: {
      type: Number,
      default: 800
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      ButtonNameMap,
      RockerTypeMap,
      zIndex: 0
    }
  },
  computed: {
    scaleX () {
      return this.width / this.data.resolutionWidth
    },
    scaleY () {
      return this.width * (9 / 16) / this.data.resolutionHeight
    },
    KeyboardInfo () {
      if (isEmpty(this.data)) return { keyInfo: {} }
      return {
        ...this.data,
        keyInfo: JSON.parse(this.data.keyInfo)
      }
    }
  },
  methods: {
    computedStyle (item) {
      const {
        keyHeight,
        keyWidth,
        keyLeft,
        keyTop
      } = item
      const scaleX = this.scaleX
      const scaleY = this.scaleY
      return {
        width: keyWidth * scaleX + 'px',
        height: keyHeight * scaleX + 'px',
        left: keyLeft * scaleX + 'px',
        top: keyTop * scaleY + 'px'
      }
    },
    computedButtonInstance (item) {
      const { rockerType, keyName } = item
      let btnName
      switch (true) {
        case rockerType === RockerTypeMap.DEFAULT:
          btnName = ButtonNameMap[keyName] || BtnCircle.name
          break
        case rockerType === RockerTypeMap.DRAG_WASD:
          btnName = BtnWASD.name
          break
        case rockerType === RockerTypeMap.GROUP:
          btnName = BtnGroup.name
          break
        case rockerType === RockerTypeMap.ROULETTE:
          btnName = BtnDisc.name
          break
        default:
          btnName = BtnUnknown.name
      }
      return btnName
    }
  }
}
</script>

<style lang="less" scoped>
  .VirtualKeyboard {
    background: #333;
    position: relative;
    border-radius: 4px;
  }

  .KeyboardBtn {
    position: absolute;
    // background: rgba(95, 158, 160, 0.5);
    border: 2px solid rgba(95, 158, 160, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
    transform: scale(.96);
}
</style>
