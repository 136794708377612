<template>
  <div
    class="KeyboardBtn BtnCaps"
    :style="{ zIndex }"
    @click="handleFocus"
  >
    <div class="content">
      <div class="icon" />
      <div class="name" />
    </div>
  </div>
</template>

<script>
import BtnMixins from './BtnMixins'
export default {
  name: 'BtnCaps',
  mixins: [BtnMixins],
  data () {
    return {
    }
  }
}
</script>

<style lang="less">
  .KeyboardBtn {
    &.BtnCaps {
      &:active {
        .content {
          transform: scale(.9);
        }
      }
      .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 40%;
          height: 40%;
          background-image: url('~@/assets/img/keyboard/caps@2x.png');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          // margin-bottom: -2px;
        }
        .name {
          width: 50%;
          height: 30%;
          background-image: url('~@/assets/img/keyboard/caps-words@2x.png');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
</style>
