export default {
  props: {
    showWords: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    maxIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      zIndex: 0
    }
  },
  created () {
    this.zIndex = this.maxIndex
  },
  methods: {
    handleFocus () {
      this.$emit('index', this.maxIndex + 1)
      this.$nextTick(() => {
        this.zIndex = this.maxIndex
      })
    }
  }
}
