// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/keyboard/mouse-left@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".KeyboardBtn.BtnMouseLeft:active .icon{transform:scale(.9)}.KeyboardBtn.BtnMouseLeft .icon{width:60%;height:60%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;background-position:50%}", ""]);
// Exports
module.exports = exports;
