<template>
  <div
    class="KeyboardBtn BtnScrollUp"
    :style="{ zIndex }"
    @click="handleFocus"
  >
    <div class="icon" />
  </div>
</template>

<script>
import BtnMixins from './BtnMixins'
export default {
  name: 'BtnScrollUp',
  mixins: [BtnMixins],
  data () {
    return {
    }
  }
}
</script>

<style lang="less">
  .KeyboardBtn {
    &.BtnScrollUp {
      &:active {
        .icon {
          transform: scale(.9);
        }
      }
      .icon {
        width: 60%;
        height: 60%;
        background-image: url('~@/assets/img/keyboard/streamdesk_common_virtual_scroll_up.webp');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
</style>
