import { render, staticRenderFns } from "./BtnScrollDown.vue?vue&type=template&id=2ad4edfd"
import script from "./BtnScrollDown.vue?vue&type=script&lang=js"
export * from "./BtnScrollDown.vue?vue&type=script&lang=js"
import style0 from "./BtnScrollDown.vue?vue&type=style&index=0&id=2ad4edfd&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports