
import request from '@/utils/request'

export function getKeyBoardList (data) {
  return request({
    url: '/search/getKeyBoardList',
    data
  })
}

export function updateKeyBoardName (data) {
  return request({
    url: '/game/updateKeyBoardName',
    data
  })
}
