<template>
  <div
    class="KeyboardBtn BtnGroup"
    :style="{ zIndex }"
    @click="handleFocus"
  >
    <template v-if="buttonList.length === 3">
      <div
        v-for="(btnName, ind) in buttonList"
        :key="ind"
        class="item-3"
      >
        <span v-if="showWords">{{ btnName }}</span>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(btnName, ind) in buttonList"
        :key="ind"
        class="item-2"
      >
        <span v-if="showWords">{{ btnName }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import BtnMixins from './BtnMixins'
export default {
  name: 'BtnGroup',
  mixins: [BtnMixins],
  data () {
    return {
    }
  },
  computed: {
    buttonList () {
      return this.item?.keyName?.split(',').slice(0, 3) || []
    }
  }
}
</script>

<style lang="less">
  .KeyboardBtn {
    position: relative;
    &.BtnGroup {
      overflow: hidden;
      .item-3 {
        position: absolute;
        bottom: 50%;
        left: 50%;
        width: 52%;
        height: 60%;
        transform-origin: 0 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(255, 255, 255, .5);
        .item-style (@index, @unit) {
          @unit-deg: @unit * 1deg;
          @skewY: @unit - 90 * 1deg;
          @order: @index - 2;
          &:nth-of-type(@{index}) {
            transform: rotate(@order * @unit-deg) skewY(@skewY);
            & > span {
              transform:  skewY(-@skewY) rotate(-@order * @unit-deg);
            }
            &:active {
              background-color: rgba(0, 0, 0, 0.3);
              >span {
                transform:  skewY(-@skewY) rotate(-@order * @unit-deg) scale(.9);
              }
            }
          }
        }
        .loop (@index, @count) when (@index < @count + 1) {
          .item-style(@index, 360 / @count);
          .loop(@index + 1, @count);
        }
        .loop(1, 3);
      }
      .item-2 {
        position: absolute;
        left: -2%;
        width: 104%;
        height: 54%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, .5);
        &:nth-of-type(1) {
          top: -2%;
        }
        &:nth-of-type(2) {
          top: 52%;
        }
        &:active {
          background-color: rgba(0, 0, 0, 0.3);
          >span {
            transform: scale(.9);
          }
        }
      }
    }
  }
</style>
