<template>
  <div
    class="KeyboardBtn BtnDisc"
    :style="{ zIndex }"
    :class="{
      closed: !isOpen
    }"
    @click="handleFocus"
  >
    <template v-if="buttonList.length > 2">
      <div
        v-for="(btnName, ind) in buttonList"
        :key="ind"
        class="key-button sector"
        :class="'item-' + buttonList.length"
      >
        <span v-if="showWords">{{ btnName }}</span>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(btnName, ind) in buttonList"
        :key="ind"
        class="key-button item-2"
      >
        <span v-if="showWords">{{ btnName }}</span>
      </div>
    </template>

    <div
      class="center-name"
      @click="isOpen = !isOpen"
    >
      <span v-if="showWords">{{ item.keyRealName }}</span>
    </div>
  </div>
</template>

<script>
import BtnMixins from './BtnMixins'
export default {
  name: 'BtnDisc',
  mixins: [BtnMixins],
  data () {
    return {
      isOpen: true
    }
  },
  computed: {
    buttonList () {
      return this.item?.keyName?.split(',').slice(0, 9) || []
    }
  }
}
</script>

<style lang="less">
  .KeyboardBtn {
    &.BtnDisc {
      overflow: hidden;
      transition: all .3s;
      &.closed {
        transform: scale(.3);
        transform-origin: 50% 50%;
        overflow: inherit;
        transition: none;
        border: none;
        .center-name {
          width: 116.6666%;
          height: 116.6666%;
          border-width: 4px;
          border-color:rgba(95, 158, 160, 0.5);
          white-space: nowrap;
          >span {
            transform: scale(3);
          }
          &:active {
            >span {
              transform: scale(3 * 0.9);
            }
          }
        }
        .key-button {
          visibility: hidden;
        }
      }
      .center-name {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35%;
        height: 35%;
        border-radius: 50%;
        border: 1px solid white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        background-color: #333;
        &:active {
          background-color: #555;
          >span {
            transform: scale(.8);
          }
        }
      }
      .key-button {
        &::after {
          content: '';
          display: flex;
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: 0;
          background: white;
        }
      }
      .item-2 {
        position: absolute;
        left: -2%;
        width: 104%;
        height: 54%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        &::after {
          width: 34%;
        }
        &:nth-of-type(1) {
          top: -2%;
          padding-bottom: 12%;
          &::after {
            left: 66%;
          }
        }
        &:nth-of-type(2) {
          top: 52%;
          padding-top: 12%;
          &::after {
            top: 0;
            left: 0;
          }
        }
        &:active {
          background-color: rgba(0, 0, 0, 0.3);
          >span {
            transform: scale(.9);
          }
        }
      }
      .sector {
        position: absolute;
        bottom: 50%;
        left: 50%;
        width: 50%;
        height: 50%;
        box-sizing: border-box;
        transform-origin: 0 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .item-style (@index, @unit) {
          @unit-deg: @unit * 1deg;
          @skewY: @unit - 90 * 1deg;
          @order: @index - 2;
          &:nth-of-type(@{index}) {
            transform: rotate(@order * @unit-deg) skewY(@skewY);
            & > span {
              transform:  skewY(-@skewY) rotate(-@order * @unit-deg);
            }
            &:active {
              background-color: rgba(0, 0, 0, 0.3);
              >span {
                transform:  skewY(-@skewY) rotate(-@order * @unit-deg) scale(.9);
              }
            }
          }
        }
        .loop (@index, @count) when (@index < @count + 1) {
          .item-style(@index, 360 / @count);
          .loop(@index + 1, @count);
        }
        &.item-3 {
          width: 52%;
          height: 60%;
           .loop(1, 3);
        }
        &.item-4 {
           .loop(1, 4);
        }
        &.item-5 {
          padding-top: 8%;
          padding-right: 13%;
           .loop(1, 5);
        }
        &.item-6 {
          padding-top: 9%;
          padding-right: 18%;
           .loop(1, 6);
        }
        &.item-7 {
          padding-top: 8%;
          padding-right: 22%;
           .loop(1, 7);
        }
        &.item-8 {
          padding-top: 10%;
          padding-right: 24%;
           .loop(1, 8);
        }
      }

    }
  }
</style>
